import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
export const _frontmatter = {
  "title": "Arbour Assembly - Finish"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Step 6. Finish`}</h1>
    <p>{`One final job left and that is to revisit all of the screws that you have put in to check that they are all tight. Whilst working on a project like this, some of the screws may not have been tightened as fully as possible and may have worked a little loose with the movement generated by assembling the structure.`}</p>
    <p>{`That’s it! Your Pergola or Arbour is now up and ready for you to enjoy allowing you to make the most of our warmer, longer summers and increase the enjoyment of a healthy, outdoor lifestyle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      